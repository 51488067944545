import * as React from "react"
import { Link, graphql } from "gatsby"

// markup
const TrainersPage = (props) => {
  const trainers = props.data.allTrainer.nodes
  
  return (
    <main>
      <h1 className="p-4 text-5xl">All Trainers</h1>
      {trainers.map(trainer => {
        return (
          <li key={trainer.id}><Link to={trainer.trainerPath}>{trainer.firstName}</Link></li>
        )
      })}
    </main>
  )
}

export default TrainersPage

export const query = graphql`
query {
  allTrainer {
    nodes {
      id
      firstName
      trainerPath: gatsbyPath(filePath: "/trainers/{Trainer.username}")
    }
  }
}
`